<template>
	<div class="">
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<!-- 系统设置 -->
		  <el-tabs type="border-card" v-model="tabIndex" @tab-click="handleClick">
		    <el-tab-pane label="基础设置">
		      <el-form label-width="150px" label-position="left">
		          <el-form-item label="系统名称" prop="shop_name">
		             <el-input :value="shop_name" @input="vModel('shop_name',$event)" placeholder="请输入系统名称" size="small"></el-input>
		          </el-form-item>
				  <el-form-item label="系统全名" prop="full_name">
				     <el-input :value="full_name" @input="vModel('full_name',$event)" placeholder="请输入系统全名" size="small"></el-input>
				  </el-form-item>
		          <el-form-item label="备案信息" prop="shop_beian">
		             <el-input :value="shop_beian" @input="vModel('shop_beian',$event)" placeholder="请输入备案信息" size="small"></el-input>
		          </el-form-item>
				  <el-form-item label="系统描述" prop="shop_desc">
				     <el-input :value="shop_desc" @input="vModel('shop_desc',$event)" placeholder="请输入系统描述" size="small" type="textarea" :rows="3"></el-input>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" size="small" @click="submit">确定</el-button>
				  </el-form-item>
		      </el-form>
		    </el-tab-pane>
			<el-tab-pane label="其他设置">
			  <el-form label-width="150px" label-position="left">
			      <el-form-item label="协会地址" prop="xh_address">
			         <el-input :value="xh_address" @input="vModel('xh_address',$event)" placeholder="请输入协会地址" size="small"></el-input>
			      </el-form-item>
				  <el-form-item label="咨询热线" prop="zxrx">
				     <el-input :value="zxrx" @input="vModel('zxrx',$event)" placeholder="请输入咨询热线" size="small"></el-input>
				  </el-form-item>
			      <el-form-item label="综合部电话" prop="zhb_phone">
			         <el-input :value="zhb_phone" @input="vModel('zhb_phone',$event)" placeholder="请输入综合部电话" size="small"></el-input>
			      </el-form-item>
				  <el-form-item label="会员部电话" prop="hyb_phone">
				     <el-input :value="hyb_phone" @input="vModel('hyb_phone',$event)" placeholder="请输入会员部电话" size="small"></el-input>
				  </el-form-item>
				  <el-form-item label="行政部电话" prop="xzb_phone">
				     <el-input :value="xzb_phone" @input="vModel('xzb_phone',$event)" placeholder="请输入行政部电话" size="small"></el-input>
				  </el-form-item>
				  <el-form-item label="培训中心电话" prop="pxzx_phone">
				     <el-input :value="pxzx_phone" @input="vModel('pxzx_phone',$event)" placeholder="请输入培训中心电话" size="small"></el-input>
				  </el-form-item>
				  <el-form-item label="协会传真" prop="xh_fax">
				     <el-input :value="xh_fax" @input="vModel('xh_fax',$event)" placeholder="请输入协会传真" size="small"></el-input>
				  </el-form-item>
				  <el-form-item label="协会邮箱" prop="xh_email">
				     <el-input :value="xh_email" @input="vModel('xh_email',$event)" placeholder="请输入协会邮箱" size="small"></el-input>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" size="small" @click="submit">确定</el-button>
				  </el-form-item>
			  </el-form>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import { mapState,mapMutations,mapGetters } from "vuex"
	import GlobalTips from "@/components/GlobalTips";
	export default {
		inject:['app'],
		components: {
			GlobalTips,
		},
		computed:{
			...mapState({
			  shop_name:state=>state.setting.shop_name,
			  company_name:state=>state.setting.company_name,
			  shop_address:state=>state.setting.shop_address,
			  shop_desc:state=>state.setting.shop_desc,
			  shop_beian:state=>state.setting.shop_beian,
			  kf_mobile:state=>state.setting.kf_mobile,
			  search_keywords:state=>state.setting.search_keywords,
			  shop_logo:state=>state.setting.shop_logo,
			  full_name:state=>state.setting.full_name,
			  xh_address:state=>state.setting.xh_address,
			  zxrx:state=>state.setting.zxrx,
			  zhb_phone:state=>state.setting.zhb_phone,
			  hyb_phone:state=>state.setting.hyb_phone,
			  xzb_phone:state=>state.setting.xzb_phone,
			  pxzx_phone:state=>state.setting.pxzx_phone,
			  xh_fax:state=>state.setting.xh_fax,
			  xh_email:state=>state.setting.xh_email,
			}),
			...mapGetters([
			  'getData',
			]),
		},
		data() {
			return {
				tabIndex:0,
			}
		},
		mounted(){
		  this.getList()
		},
		methods:{
			...mapMutations(['editState','editParamsState']),
			vModel(key,val){
			  this.editState({key,val})
			},
			vModelParams(name,key,val){
			  this.editParamsState({name,key,val})
			},
			handleClick(tab, event) {
			 // console.log(tab, event);
			},
			getList(){
			  this.axios.get('/manage/setting/index').then(res=>{
				if(res.status){
				  if(res.data.length > 0){
					  this.$store.commit('initSetting',res.data)
				  }
				}
			  })
			},
			submit(){
			  this.axios.post('/manage/setting/save',this.getData).then(res=>{
				if(res.status){
				  this.$message.success(res.msg)
				}else{
				  this.$message.error(res.msg)
				}
			  })
			},
		}
	}
</script>

<style>
</style>